import React, { useState, useEffect } from "react";
import "./HeroTitle.css";
import "./MainHero.css";
import { Link } from "react-router-dom";
import HeroImage from "./HeroImage";

const MainHeroTitle = ({}) => {
  const title = "The Next Level";
  const words = title.split(" "); // Split the title into an array of words
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [words.length]);
  return (
    <div className="pt-14 md:pt-24 lg:py-14 mb-[60px]">
      <div className="w-full blur-[160px] rounded-full h-[32vh] md:h-32 absolute bg-cyan-200/10 z-10 top-[30vh] md:top-40" />
      <div className="w-full blur-[200px] rounded-full h-[52vh] md:h-72 absolute bg-indigo-700/30 -z-10 top-[10vh] md:top-32" />
      <div className="mx-auto w-auto lg:w-[1200px] items-center">
        <div className="text-center text-4xl px-1 lg:px-0 md:text-7xl lg:text-7xl">
          <h1
            className="w-auto lg:w-[1200px] mb-6 md:mb-1 z-20
                      text-center font-semibold tracking-tighter line-clamp-2"
          >
            <a
              className="font-extrabold tracking-tight pb-1
                          bg-gradient-to-r from-indigo-300 via-[#CCFFFF]-300/90 to-slate-300
                          inline-block text-transparent bg-clip-text"
            >
              Take Your Trading To
            </a>
            <h1 className="animated-word text-[2.5rem] md:text-6xl lg:text-[5rem]">
              {words.map((word, index) => (
                <span
                  key={index}
                  className={index === currentWordIndex ? "animated-word" : ""}
                >
                  {word}&nbsp;
                </span>
              ))}
            </h1>
          </h1>
          <a
            className="hero-content mx-auto mb-[10vh] md:mb-[28px] font-medium tracking-wider w-[300px] md:w-[400px] lg:w-[500px]
                      text-slate-100
                      text-sm md:px-0 md:text-lg
                      inline-block"
          >
            Transform your trading with our fully automated futures trading
            algos.
          </a>
          <HeroImage />

          <button className="ui-btn mx-auto inline-block mt-[10vh] md:mt-0">
            <Link to="/strategies">
              <span>View Algos</span>
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainHeroTitle;
