import React from "react";
import Disclaimer from "../components/Disclaimer";

const ReturnPolicy = () => {
  const returnPolicyContent = (
    <>
      <p>
        Our backtesting software is sold as-is, without any warranties or
        guarantees of any kind. The software is intended for testing and
        educational purposes only and should not be considered as financial or
        investment advice. By purchasing and using our backtesting software, you
        acknowledge and accept that:
      </p>
      <ul>
        <li>
          We are not liable for any losses or damages incurred from the use of
          the software.
        </li>
        <li>
          The effectiveness and profitability of the software may vary depending
          on various factors such as market conditions, user strategies, and
          data quality.
        </li>
        <li>
          It is your responsibility to ensure that any trading or investment
          decisions made based on the software's results are suitable and
          appropriate for your individual circumstances.
        </li>
      </ul>
      <p>
        We strongly recommend conducting thorough research and seeking
        professional advice before making any financial or investment decisions.
        It's important to understand the risks involved in trading and investing
        and to use the software responsibly.
      </p>
    </>
  );

  return (
    <div>
      <Disclaimer title="Return Policy" content={returnPolicyContent} />
    </div>
  );
};

export default ReturnPolicy;
