import React from "react";
import { useParams } from "react-router-dom";
import "./Post.css";
import authorAvatar from "../../images/jade_smiling_avi.jpg";
import post4Image from "../../images/man_vs_machine.png";
import HeroTitle from "../HeroTitle";

const Post4 = () => {
  let { post_title } = useParams();
  return (
    <div>
      <div className="hero-content text-left mb-[20px]">
        <h1 className="flex-grow pb-[14px] ] mt-[20px] md:mt-4 mb-2 text-center font-bold tracking-tighter
                      bg-gradient-to-b from-cyan-300 to-rose-200
                      text-transparent bg-clip-text pr-2
                      md:mb-6 text-8xl">
          Blog<a/>
        </h1>
        <div
            className="w-full blur-[160px] rounded-full h-72 absolute mt-[200px]
            bg-gradient-to-b from-indigo-400/20 to-rose-400/10 -z-20"
        ></div>
      </div>
      <div className="post-container">
        <div className="author-section">
          <img
              src={authorAvatar}
              alt="Author Avatar"
              className="author-avatar"
          />
          <div className="author-details">
            <p className="author-name">Jade Byfield</p>
            <p className="publish-date">October 10, 2023</p>
            <p className="read-time">3 minute read</p>
          </div>
        </div>
        <h1 className="font-bold text-3xl mt-4 mb-8">Psychology vs. Algorithms: Emotions in Trading</h1>

        <p>
          The world of trading is a battleground where emotions often clash with
          rational decision-making. In this blog post, we'll delve into the
          fascinating interplay between human psychology and algorithmic
          trading, and how the latter can be a powerful tool to mitigate the
          impact of emotions on trading decisions.
        </p>

        <h2 className="font-semibold text-lg mt-4 mb-4">The Impact of Emotions in Trading:</h2>

        <p>
          Human emotions such as fear, greed, and euphoria can cloud judgment
          and lead to impulsive trading decisions. Traders may buy or sell based
          on emotions rather than well-researched strategies, which can result
          in significant losses.
        </p>

        <h2 className="font-semibold text-lg mt-4 mb-4">Algorithmic Trading as a Solution:</h2>

        <p>
          Algorithmic trading systems, often referred to as "algos," execute
          trades based on predefined rules and algorithms. They are immune to
          emotional biases and can execute trades with discipline and precision,
          following a well-defined strategy.
        </p>

        <h2 className="font-semibold text-lg mt-4 mb-4">Advantages of Algorithmic Trading:</h2>

        <ul>
          <li>
            <strong>Discipline:</strong> Algorithms follow a set of rules
            without deviation, eliminating impulsive decisions.
          </li>
          <li>
            <strong>Consistency:</strong> Algorithmic systems can maintain
            consistency in trade execution, even in high-stress market
            conditions.
          </li>
          <li>
            <strong>Backtesting:</strong> Strategies can be rigorously tested
            using historical data to assess their viability.
          </li>
          <li>
            <strong>Emotion-Free:</strong> Algos do not experience fear, greed,
            or other emotions that can affect human traders.
          </li>
        </ul>

        <h2 className="font-semibold text-lg mt-4 mb-4">Human-Machine Collaboration:</h2>

        <p>
          While algorithms offer significant advantages, human insight and
          judgment are still invaluable in trading. Many successful traders use
          algorithms as tools to support their decision-making rather than
          replace it entirely.
        </p>

        <h2 className="font-semibold text-lg mt-4 mb-4">Conclusion:</h2>

        <p>
          The battle between psychology and algorithms in trading is ongoing.
          Recognizing the role of emotions and the benefits of algorithmic
          trading can help traders navigate turbulent financial markets with
          greater confidence and success.
        </p>

        <p>
          Stay tuned for more articles on trading psychology, algorithmic
          strategies, and ways to achieve a balanced approach to trading.
        </p>
        <br/><br/>
        <img
          src={post4Image}
          className="blogImage"
          alt="Man dressed in suit who looks stressed, facing a robot who looks focused and determined. Showing the battle of emotions vs algorithms"
        ></img>
      </div>
    </div>
  );
};

export default Post4;
