import React, { useState, useEffect } from 'react';
import './HeroTitle.css';

const HeroTitle = ({ title, subtitle }) => {
  const words = title.split(' '); // Split the title into an array of words
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <div className="hero">
      <div className="hero-content">
        <h1 className="animated-word text-[2.5rem] md:text-6xl lg:text-[5rem] pb-2">
          {words.map((word, index) => (
            <span
              key={index}
              className={index === currentWordIndex ? 'animated-word' : ''}
            >
              {word}&nbsp;
            </span>
          ))}
        </h1>
        <h2>{subtitle}</h2>
      </div>
    </div>
  );
};

export default HeroTitle;
