import React, { useEffect, useState } from "react";
import ContactPopup from "./ContactPopup";

function DelayedPopup({ delay }) {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const hasPopupBeenShown = localStorage.getItem("popupShown");

      if (!hasPopupBeenShown) {
        // Uncomment this if you want the popup to show only once per storage interval.
        setShowPopup(true);
        localStorage.setItem("popupShown", "true");
      }
    }, delay);

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, [delay]);

  const handleClose = () => {
    setShowPopup(false); // This function will be passed to ContactPopup to close it
  };

  return showPopup ? (
    <ContactPopup
      buttonText="Get My Free NinjaTrader Algo"
      onClose={handleClose} // Pass the handleClose function to ContactPopup
    />
  ) : null;
}

export default DelayedPopup;
