import React, { useState, useEffect } from "react";
import "./HeroTitle.css";

const SectionTitleEmotions = ({ title, subtitle }) => {
  title = "Automation Over Emotions";
  subtitle =
    "Stop struggling with your emotions and let our profitable trading algorithms give you a real edge in the markets. ";
  return (
    <div className="hero-content mx-auto pt-[128px] pb-[28px] w-auto md:w-[1200px] items-center">
      <div
        className=" w-full blur-[200px] rounded-full h-60 absolute
            bg-gradient-to-b from-black to-transparent -z-20"
      ></div>
      <div className="w-full blur-[200px] rounded-full h-72 absolute bg-gradient-to-b from-[#1A0B59]/90 to-sky-700/70 -z-20"></div>

      <div className="hero-content text-center">
        <h1
          className="flex-grow mt-0 md:mt-4 text-center font-bold tracking-tighter
                      bg-gradient-to-r from-slate-100 via-sky-200 to-slate-700
                      text-transparent bg-clip-text
                      text-5xl mb-4 md:mb-6 md:text-6xl lg:text-7xl"
        >
          Automation Over <a />
          <a
            className="bg-gradient-to-b from-[#5b42f3]/10 to-[#580DFA] bg-clip-text
                  text-transparent font-semibold tracking-widest md:tracking-wide"
          >
            {" "}
            Emotions
          </a>
        </h1>
        <a
          className="font-medium tracking-tighter text-slate-300
                      md:tracking-widest w-[60vw] md:w-[500px] text-md lg:px-0 md:text-md lg:text-lg
                      inline-block"
        >
          <span>{subtitle}</span>
        </a>
        <div
          className="w-[70vw] blur-[160px] rounded-full h-52 absolute
            bg-gradient-to-t from-[#2C1036]/70 to-black -z-20"
        ></div>
      </div>
    </div>
  );
};

export default SectionTitleEmotions;
