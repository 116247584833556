import React from "react";
import { useParams } from "react-router-dom";
import Post1 from "./blogposts/Post1";
import Post2 from "./blogposts/Post2";
import Post3 from "./blogposts/Post3";
import Post4 from "./blogposts/Post4";

function BlogPost() {
  const { id } = useParams();

  // Create a mapping of post IDs to their respective components
  const postComponents = {
    1: <Post1 />,
    2: <Post2 />,
    3: <Post3 />,
    4: <Post4 />,
    // Add more entries for other post IDs and components
  };

  // Use the post ID parameter to select the appropriate component
  const selectedComponent = postComponents[id];

  if (!selectedComponent) {
    // Handle cases where the post ID doesn't match any known component
    return (
      <div>
        <h1>Post Not Found</h1>
        <p>The requested blog post does not exist.</p>
      </div>
    );
  }

  return (
    <div className="pb-[40px]">
      {/* Render the selected component */}
      {selectedComponent}
    </div>
  );
}

export default BlogPost;
