import React from 'react';
import HeroTitle from '../components/HeroTitle'
import ninjaTraderLogo from '../images/ninjatrader-logo.png'
import './TradingPlatform.css'
import ntImage from '../images/desktop_mockup_2.png'
import kinetickImage from '../images/Kinetick-FreeEOD-Animated-300x250.gif'
import kinetickImage2 from '../images/kinetick_free_eod_image1.png'


const TradingPlatform = () => {
    return (
        <div>
        <div>
            <HeroTitle title='Trading Platform' subtitle="Join the world's best professional traders on our favorite platform."></HeroTitle>
        </div>
        <div className="logo-container">
        <img src={ninjaTraderLogo} className="logo-image" alt="NinjaTrader Logo" />
        </div>
        <img src={ntImage} alt="NT Image" className="nt-image"></img>
        
        <div className="nt-content">
        <p className="nt-heading"><strong>Our Recommended Trading Platform</strong></p>
        <p>NinjaTrader<sup>®</sup> is our #1 recommended trading software platform preferred by traders worldwide including our clients.&nbsp;
        </p>
        <p><a href="http://ninjatrader.com">Download NinjaTrader</a><strong> &amp; receive immediate FREE access to:</strong></p>
        <ul>
<li>Real-time futures data</li>
<li>Advanced charting</li>
<li>Trade simulator</li>
<li>Strategy development and backtesting</li>
</ul>
<p>NinjaTrader’s award-winning <a href="http://ninjatrader.com/GetStarted">trading platform</a> is consistently voted an industry leader by the trading community.&nbsp; Featuring 1000s of Apps &amp; Add-Ons for unlimited customization, NinjaTrader is used by over 500,000 traders for advanced market analysis, professional charting and fast order execution.</p>
<p>For new traders, start preparing for the live markets with a free <a href="http://ninjatrader.com/Simulate">trading simulator</a> featuring real-time market data.</p>
<p><a href="http://ninjatrader.com">Get Started for FREE!</a></p>
        </div>
<div className="nt-content">
 <p className="nt-heading"><strong>Our Recommended Market Data Feed</strong></p> 
 </div>     
 <div className='nt-content'>
     <a href='http://kinetick.com/NinjaTrader'>
 <img src={kinetickImage2} className="kinetick-image" alt="Kinetick Image" />
 </a>
 <p>Kinetick® delivers reliable, fast and cost-effective <a href='http://kinetick.com/NinjaTrader'>market data </a> to help level the playing
field for active traders. Take advantage of unfiltered, real time quotes for stocks, futures
and forex that exceed the expectations of the world’s most demanding traders, like us! &nbsp;</p>

<p>Get started with FREE end-of-day <a href='http://kinetick.com/NinjaTrader'>historical market data</a> directly through the
NinjaTrader platform and learn how you can significantly reduce CME Group Globex
exchange fees on real-time market data with Kinetick. </p>

<p><a href="http://kinetick.com/NinjaTrader"><strong>Get Started with Free EOD Data</strong></a></p>


 </div>


        </div>

    );
};

export default TradingPlatform;
