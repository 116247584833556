import React from "react";
import live_corr from "../images/portfolio_analyzer/quant_1_portfolio_correlation.png";
import "../pages/QuantPortfolio.css";

function PortfolioCorrelationSection() {
  return (
    <section className="flex flex-wrap md:gap-8 md:pt-12 items-start max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col ml-2 rounded-none min-w-[240px] md:w-[541px] mt-8 max-md:max-w-full">
        <h2 className="flex gap-4 self-start text-3xl md:text-4xl text-center whitespace-nowrap">
          <p className="grow self-start tracking-tighter text-slate-300">
            Portfolio
          </p>
          <p className="tracking-wide text-transparent bg-clip-text basis-auto bg-gradient-to-r from-[#569DB7] to-[#C2D1FB]">
            Correlation
          </p>
        </h2>
        <p className="mt-12 md:text-xl font-medium tracking-tight leading-6 text-zinc-300 max-md:max-w-full">
          Understand the dynamics within your investment portfolio with our
          advanced Portfolio Correlation tool. This feature analyzes the
          relationships between your portfolio's algorithms, helping you
          visualize how they interact with each other under various market
          conditions.
          <br />
          <br />
          By calculating the correlation coefficients between different systems
          in your portfolio, you can easily identify which algorithms move in
          tandem and which ones provide diversification benefits.
        </p>
      </div>
      <div className="md:pb-12 min-w-[392px] w-[693px]">
        <div
          className="justify-center relative z-10 md:mb-14 md:min-w-[240px] md:h-[520px]
                          rounded-2xl pb-20 max-md:max-w-full overflow-hidden drop-shadow-2xl"
        >
          <div className="bg-hero-perf bg-no-repeat h-[352px] md:h-[520px]" />
        </div>
        <div
          className="w-full h-72 -mt-80  z-0 relative
                      bg-gradient-to-r from-[#8a3c73]/50 to-[#1c3a5a]/50 blur-3xl"
        />
      </div>
    </section>
  );
}

export default PortfolioCorrelationSection;
