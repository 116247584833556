import React from 'react';
import './TradingPlatform.css'
import { Fade } from "react-reveal";
import Banner from "../components/Banner"
import ParallaxHero from "../components/ParallaxHero";
import FeatureSection from "../components/FeatureSection";
import FeatureSectionStrategy from "../components/FeatureSection_Strategy";
import PortfolioAnalyzerCta from "../components/PortoflioAnalyzerCTA";
import PortfolioAnalyzerContent from "../components/PortfolioAnalyzerContent";
import BannerNoEffect from "../components/BannerNoEffect";

const PortfolioAnalyzer = () => {
    return (
        <div className="overflow-x-hidden">
            {<PortfolioAnalyzerContent/>}
            {/*<ParallaxHero/>*/}
            <div className="mb-10">
                <BannerNoEffect/>
            </div>
            <FeatureSection/>
            <FeatureSectionStrategy/>
            <PortfolioAnalyzerCta/>
        </div>
    );
};

export default PortfolioAnalyzer;
