import React from 'react';
import './HoverCard.css';
import {CardContent, CardMedia, Grid, Typography} from "@material-ui/core"; // Assuming you have some CSS for styling the card

const HoverCard = ({ title, description, imageSrc = null }) => {
    return (
        <div className="card">
                    <div className="content">
                            <div className="absolute inset-0">
                                        <CardMedia component="img" image={imageSrc} alt="Card Image" load/>

                                <CardContent style={{alignItems: "between"}}>

                                    <Grid container spacing={1} direction={"column"} justifyContent={"flex-start"}>
                                        <Grid item xs>
                                                <Typography variant="h5" component="h2"
                                                            style={{
                                                                color: "white",
                                                                fontFamily: 'Montserrat',
                                                                fontSize: "1.5rem",
                                                                fontWeight: '900',
                                                                justifyContent: 'start'
                                                            }}>{title}</Typography>
                                        </Grid>
                                        <Grid item xs>

                                            <div className="pt-0">
                                                <Typography variant="body2" color="textSecondary" component="p"
                                                            style={{
                                                                color: "white",
                                                                justifyContent: 'end'
                                                            }}>{description}</Typography>
                                                </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </div>
                    </div>
        </div>
    );
};

export default HoverCard;