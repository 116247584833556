"use client";

import React, {useEffect, useRef} from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import clsx from "clsx";
import HeroTitle from "./HeroTitle";

const BannerNoEffect = () => {

  const headings = ["Analyze a portfolio of ZTA Strategies",
    "With Trading Metrics & Interactive Charts",
    "For Historic & Live Data"]
  return (
      <header className="border-b-0 bg-inherit text-center">
          <div className="flex flex-col whitespace-nowrap">
              {headings.map((heading, index) => (
                  <div
                      key={index}
                      className={clsx("text-[1.2rem] md:text-[3rem] lg:text-[3.25rem] mt-10", {
                              "self-end": index % 2 !== 0,
                          }
                      )}
                  >
                      {/*<HeroTitle title={heading} subtitle=""/>*/}
                      <p className="hover:text-emerald-400/100 font-extrabold sm:tracking-widest md:tracking-tight
                          bg-gradient-to-r from-indigo-500 via-red-300/60 to-pink-500
                          text-transparent bg-clip-text">
                          {heading}
                      </p>
                  </div>
              ))}
          </div>
      </header>
  );
};

export default BannerNoEffect;

