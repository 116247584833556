import React from 'react';
import HeroTitle from '../components/HeroTitle'
import './About.css'
import SectionAboutUs from "../components/SectionAboutUs";

const About = () => {
    return (
        <div className="overflow-auto">
            <SectionAboutUs/>
            {/*<HeroTitle title='About Us' subtitle="We're a team of anti gurus. No scams, no tricks, no gimmicks. Just automated trading systems."></HeroTitle>*/}
            {/*<div className="main-container">*/}
            {/*<p>We created ZTA because we noticed that the journey to profitable trading is absolutely brutal for the retail trader. It's said that 95% of retail traders fail. We believe that */}
            {/*    the main reason for this comes down to two things: Not having a technical edge, and emotions.*/}
            {/*</p>*/}

            {/*<p>We knew that there had to be a better way. We're a team of experienced Software Engineers and Traders with 10+ years in the tech and trading industries. */}
            {/*   We tirelessy researched strategies, indicators, crowd pyschology behaviors and tools to help us find an edge in the markets. We succeeded.*/}
            {/*</p>*/}

            {/*<p>Zion Trading Algos is our offering to the average retail trader. These tools were not available to us a few years ago, and it's time to leverage technology, code */}
            {/*    and computing power to our advantage. You started trading because you wanted financial freedom, and to free up your time, so why do you spend 6 hours a day staring at charts?*/}
            {/*</p>*/}

            {/*<p>Let our systems do the heavy lifting, so that you can get back to what matters to you the most.</p>*/}
            {/*</div>*/}
        </div>
    );
};

export default About;
