import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component/dist-modules";
import iconBrainstorm from "../images/icon_brainstorm.png";
import iconCoding from "../images/icon_coding.png";
import iconBacktesting from "../images/icon_backtesting.png";
import iconWFO from "../images/icon_wfo.png";
import iconMCS from "../images/icon_mc.png";
import iconIncubation from "../images/icon_incubation.png";
import iconLive from "../images/icon_live.png";
import React from "react";

const SectionVerticalTimeline = () => {
  const subtitle =
    "Carefully crafted process for the design, testing and deployment of our algorithms.";
  return (
    <div>
      <div className="hero-content md:pt-[100px] text-center mb-[56px] bg-gradient-to-b from-black/60 to-transparent">
        <h1
          className="flex-grow pb-[14px] mt-20 md:mt-4 font-bold tracking-wide
                      bg-gradient-to-b from-indigo-200/90 via-sky-200 to-slate-200
                      text-transparent bg-clip-text
                      text-5xl mb-4 md:mb-6 md:text-6xl lg:text-7xl"
        >
          Our Robust <br />
          <a
            className="bg-gradient-to-b from-blue-400 to-teal-500 bg-clip-text
                  text-transparent font-semibold tracking-wide"
          >
            {" "}
            7 Step
          </a>{" "}
          Process
        </h1>
        <a
          className="font-normal text-slate-300
                      md:tracking-widest w-[90vw] md:w-[500px] text-md lg:px-0 md:text-md lg:text-lg
                      inline-block"
        >
          <span>{subtitle}</span>
        </a>
      </div>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "hsl(15, 90%, 55%)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid hsl(15, 90%, 55%)" }}
          date="Step 1"
          iconStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "hsl(15, 90%, 55%)",
            color: "#fff",
          }}
          icon={
            <img
              src={iconBrainstorm}
              alt="IconStep1"
              style={{ width: "30px", height: "30px" }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">Theory Brainstorm</h3>
          <p>
            Our team of experienced traders, programmers and data scientists
            engage in a rigorous brainstorming session, leveraging their
            industry knowledge and cutting-edge research to generate innovative
            ideas for high-performing trading strategies.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "hsl(30, 90%, 55%)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid hsl(30, 90%, 55%)" }}
          date="Step 2"
          iconStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "hsl(30, 90%, 55%)",
            color: "#fff",
          }}
          icon={
            <img
              src={iconCoding}
              alt="IconStep1"
              style={{ width: "30px", height: "30px" }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">Code Validation</h3>

          <p>
            Once a promising trading concept is identified, our team dives into
            code validation, meticulously reviewing and refining the algorithmic
            code to ensure accuracy, reliability, and efficiency.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "hsl(45, 90%, 55%)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid hsl(45, 90%, 55%)" }}
          date="Step 3"
          iconStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "hsl(45, 90%, 55%)",
            color: "#fff",
          }}
          icon={
            <img
              src={iconBacktesting}
              alt="IconStep1"
              style={{ width: "30px", height: "30px" }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">Backtesting</h3>
          <p>
            We subject the trading strategy to rigorous backtesting, simulating
            its performance using historical market data to evaluate its
            potential profitability, risk management, and overall effectiveness.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "hsl(70, 90%, 45%)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid hsl(70, 90%, 45%)" }}
          date="Step 4"
          iconStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "hsl(70, 90%, 45%)",
            color: "#fff",
          }}
          icon={
            <img
              src={iconWFO}
              alt="IconStep1"
              style={{ width: "30px", height: "30px" }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Walk-Forward-Optimization
          </h3>
          <p>
            To adapt the trading strategy to real-world market conditions, we
            employ walk-forward optimization techniques, continuously
            fine-tuning and adapting the algorithm to achieve robustness and
            optimal performance across different market scenarios.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: "hsl(80, 90%, 35%)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid hsl(80, 90%, 35%)" }}
          date="Step 5"
          iconStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "hsl(80, 90%, 35%)",
            color: "#fff",
          }}
          icon={
            <img
              src={iconMCS}
              alt="IconStep1"
              style={{ width: "30px", height: "30px" }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">
            Monte Carlo Simulation
          </h3>
          <p>
            Rigorous stress testing using Monte Carlo simulation enables us to
            assess the strategy's resilience and performance under various
            random market scenarios, ensuring it can withstand uncertainties and
            deliver consistent results.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: "hsl(90, 90%, 40%)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid hsl(90, 90%, 40%)" }}
          date="Step 6"
          iconStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "hsl(90, 90%, 40%)",
            color: "#fff",
          }}
          icon={
            <img
              src={iconIncubation}
              alt="IconStep1"
              style={{ width: "30px", height: "30px" }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">Incubation </h3>
          <p>
            The selected strategies undergo a period of incubation, where they
            receive continuous monitoring, refinement, and adjustments based on
            ongoing market analysis and performance evaluation.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: "hsl(120, 35%, 20%)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid hsl(120, 35%, 20%)" }}
          date="Step 7"
          iconStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "hsl(120, 35%, 20%)",
            color: "#fff",
          }}
          icon={
            <img
              src={iconLive}
              alt="IconStep1"
              style={{ width: "30px", height: "30px" }}
            />
          }
        >
          <h3 className="vertical-timeline-element-title">Deployment</h3>

          <p>
            Finally, the thoroughly developed and tested trading strategies are
            deployed, ready to be integrated into live trading environments,
            empowering traders to leverage our proven systems for automated
            futures trading success.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default SectionVerticalTimeline;
