import React, { useState, useEffect } from 'react';
import './HeroTitle.css';
import PricingCards from "./PricingCards";

const SectionPricing = ({ title, subtitle}) => {

  return (
      <div
          className="hero-content mx-auto pt-[20px] bg-inherit w-full mb-[56px]">
          <div className="hero-content text-left mb-[28px]">
              <h1 className="pb-[28px]
                  text-center font-medium tracking-tighter
                  bg-gradient-to-b from-neutral-200 to-neutral-100/70
                  text-transparent bg-clip-text
                  text-6xl md:text-7xl lg:text-7xl">
                  {title}
              </h1>
              <a className="font-medium tracking-widest w-[90vw] md:w-[500px]
                      text-emerald-300
                      text-lg lg:px-0 md:text-md lg:text-lg
                      inline-block">
                  <span>{subtitle}</span>
              </a>
          </div>
          <PricingCards/>
          <div
              className="pt-[40vh] w-full blur-[200px] rounded-full h-82 absolute bg-gradient-to-b from-black to-slate-700 -z-20"
          ></div>
      </div>
  );
};

export default SectionPricing;
