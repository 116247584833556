"use client";
import imgHistoricEquity from "../images/portfolio_analyzer/historic_equity_chart.png";
import imgHistoricDrawdown from "../images/portfolio_analyzer/historic_drawdown_chart.png";
import imgHistoricCorrelation from "../images/portfolio_analyzer/historic_correlation_matrix.png";


import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import React, {useEffect, useRef, useState} from "react";
import clsx from "clsx";
import Banner from "./Banner";
import HeroTitle from "./HeroTitle";
import { Fade } from "react-reveal";

const FeatureSection = () => {
    const sectionRef = useRef(null);
    const images = [
    {
      src: imgHistoricEquity,
      alt: "Equity Chart",
    },
    {
      src: imgHistoricDrawdown,
      alt: "Drawdown Chart",
    },
    {
      src: imgHistoricCorrelation,
      alt: "Correlation Matrix",
    },
  ];

    const contents = [
    {
      tagline: "Optimize Your Trading Strategies",
      heading: "Identify The Most Profitable Algorithms In Your Portfolio",
      description:
        "Analyze individual strategies or your complete Portfolio with our interactive charts!",
      image: {
        src: imgHistoricEquity,
        alt: "Placeholder image 1",
      },
    },
    {
      tagline: "Portfolio Drawdown",
      heading: "Utilize Drawdown Charts to Safeguard Your Investments",
      description:
        "Visualize, identify and mitigate risks effectively with advanced risk management tools!",
      image: {
        src: imgHistoricDrawdown,
        alt: "Placeholder image 1",
      },
    },
    {
        tagline: "Strategy Correlations",
        heading: "Strengthen Portfolio Diversification for Efficient Strategy Alignment",
        description:
            "Optimize algorithmic synergies by identifying correlations between strategies with our Correlation Matrix Tool!",
        image: {
            src: imgHistoricCorrelation,
            alt: "Placeholder image 1",
        },
    }
    ]

    return (
        <section className="px-[10%] -py-16 md:py-24 lg:py-12 bg-gradient-to-r from-neutral-300 to-gray-300">
            {contents.map((content, index) => (
                <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className={clsx("items-stretch w-[95%] " +
                        "bg-gradient-to-r text-transparent bg-clip-text", {
                        "items-center justify-center from-emerald-500/90 via-emerald-600/90  to-green-400": index === 0,
                        "text-end justify-right from-pink-500/90 via-red-600/90 to-red-400": index === 1,
                        "items-center justify-center from-sky-500/90 via-blue-600/90 to-sky-400": index === 2
                    })}>
                        {/*<p className="mb-1 font-semibold text-xl text-sky-600 md:mb-4">{content.tagline}</p>*/}
                        <h2 className="mb-3 mt-10 md:mt-0 font-bold tracking-tighter
                  text-2xl md:mb-6 md:text-4xl lg:text-5xl">
                            {content.heading}
                        </h2>
                        <p className="md:text-md tracking-widest text-slate-600/50 font-medium text-lg">{content.description}</p>
                    </div>
                    <div className={clsx("mb-[15vh]", {
                    "md:order-first": index % 2 !== 0
                    })}>
                        <img src={content.image.src} className="w-auto"
                             alt={content.image.alt}/>
                    </div>
                </div>
            ))}
        </section>
    );
};

export default FeatureSection;
