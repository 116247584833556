const gtagReportConversion = (url, value) => {
    var callback = function () {
      if (typeof(url) !== 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      'send_to': 'AW-11262360879/xSZVCOfIudMYEK_6p_op',
      'value': value,
      'currency': 'USD',
      'event_action': 'Begin checkout',
      'event_label': 'Clicked Buy or Subscribe Button',
      'event_callback': callback
    });
    return false;
  };
  
  export default gtagReportConversion;