import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeroTitle from "../components/HeroTitle";
import "./Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  // New state for subtitle
  const [subtitle, setSubtitle] = useState(
    "We're open to questions, comments, feedback, and even ideas!"
  );

  // Get the location object from useLocation hook
  const location = useLocation();

  // useEffect hook to check the previous path
  useEffect(() => {
    // Check if the user navigated from "/affiliates"
    console.log("Came from " + location.state);
    if (location.state && location.state.from === "/affiliates") {
      setSubtitle(
        "Want to join our affiliate program? Send us a message with your info, and include any links to your social media profiles, website, or blog."
      ); // Placeholder text
    }
  }, [location]); // Depend on location to re-run when location changes

  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://ztabackend-8c250e04a1fb.herokuapp.com/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // Email sent successfully
        setSubmitted(true);
        // Clear form fields
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } else {
        // Error sending email
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error handling contact form submission:", error);
    }
  };

  return (
    <div className="contact-container">
      <HeroTitle title="Contact Us" subtitle={subtitle} />{" "}
      {/* Use the state here */}
      <div className="form-container">
        {submitted ? (
          <div className="success-message">
            <h1>Thank You!</h1>
            <p>
              Your message has been successfully sent. We will contact you very
              soon!
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <h1 className="form-title">Contact Form</h1>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter your message"
                required
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Contact;
