import React from "react";
import icon_how from "../images/stickynote.svg";
import icon_instruments from "../images/setting-2.svg";
import icon_account from "../images/frame.svg";

const featureData = [
  {
    icon: icon_how,
    title: "How It Works",
    description:
      "The ZTA Quant Portfolio is for long term thinkers, who understand the power of diversification across markets, timeframes, and strategy types.",
  },
  {
    icon: icon_instruments,
    title: "Instruments",
    description: "NQ, ES, GC, CL and NG futures on various timeframes.",
  },
  {
    icon: icon_account,
    title: "$100,000 Account Size",
    description:
      "The ZTA Quant Portfolio has shown stable performance in historical backtests, and we recommend a simulation account size of at least $100,000 to test it.",
  },
];

function FeatureCard({ icon, title, description }) {
  return (
    <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
      <div className="relative group">
        <div
          className="absolute top-32 justify-center md:h-[187px] -inset-1 bg-gradient-to-b from-[#8A3C73] to-[#1C3A5A]
              rounded-lg blur-[46px]
              opacity-0 group-hover:opacity-40 transition duration-1000 group-hover:duration-200"
        ></div>
        <div
          className="bg-slate-300/20 group-hover:bg-gradient-to-b from-[#B34F45] to-[#5F42B1]
          transition duration-1000 group-hover:duration-200 rounded-[8px] w-full lg:w-[428px] h-[200px] md:h-[270px] p-[1px]"
        >
          <div
            className="flex flex-col grow items-start px-6 py-5 lg:py-8 mt-0 max-md:max-w-full
            rounded-[8px] bg-[#141721] relative p-2
            w-full h-full"
          >
            <div className="border border-slate-700/90 rounded-full shadow-md shadow-black p-[4px]">
              <img
                loading="lazy"
                src={icon}
                alt=""
                className="object-contain w-8 rounded-full aspect-square"
              />
            </div>
            <h3 className="mt-4 lg:mt-7 text-xl md:text-lg lg:text-3xl tracking-tighter leading-none text-[#C4D1E6]">
              {title}
            </h3>
            <p className="self-stretch mt-4 text-[12px] lg:text-base font-medium tracking-tight leading-6 text-[#D8D8D8]">
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function QuantFeatureCards() {
  return (
    <section className="w-full rounded-none max-md:mt-10">
      <div className="flex gap-5 max-md:flex-col">
        {featureData.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </section>
  );
}

export default QuantFeatureCards;
