import React, { useEffect } from "react";
import "./Navbar.css";
import logoImage from "../images/zta_logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector("nav");
      if (window.scrollY > 0) {
        navbar.classList.add("navbar-transparent");
      } else {
        navbar.classList.remove("navbar-transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav>
      <div className="nav_logo">
        <Link to="/">
          <img src={logoImage} alt="Logo"></img>
        </Link>
        <h1>ZTA</h1>
      </div>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/strategies">Strategies</Link>
        </li>
        <li>
          <Link to="/portfolio_analyzer">Portfolio Analyzer</Link>
        </li>
        <li>
          <a
            href="https://calendly.com/jjbyfield/30min"
            target="_blank"
            rel="noopener noreferrer"
          >
            Book A Demo
          </a>
        </li>
        {/* <li>
          <Link to="/contact">Contact Us</Link>
        </li> */}
        <li>
          <Link to="/faq">FAQ</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        {/* <li>
          <Link to="/affiliates">Become An Affiliate</Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
