import videoLiveStrategies from "../images/portfolio_analyzer/live_strategies.mp4";
import React, { useRef} from "react";

const FeatureSectionStrategy = () => {
    const sectionRef = useRef(null);

    const content =
    {
      heading: "Create A Portfolio Tailored To Your Needs",
      description: "Toggle different strategies and watch your metrics and charts update accordingly. " +
          "Simulate different combinations of strategies effortlessly to fit your specific needs and make informed decisions!",
    }

    return (
        <section className="px-[5%] lg:px-[10%] py-12 bg-gradient-to-b from-black to-slate-950">
            <div className="grid grid-col-2 mt-[5%]
                        bg-gradient-to-r text-transparent bg-clip-text
                        text-end from-pink-300/90 via-sky-400/80 to-pink-200">
                <h2 className="text-center font-bold tracking-tighter text-2xl sm:mb-2 md:mb-6 md:text-5xl lg:text-6xl">
                    {content.heading}
                </h2>
                <p className="px-[10%] text-center leading-tight sm:text-sm md:text-lg tracking-widest text-slate-300/50 font-light">{content.description}</p>

            </div>
            <div className="mb-[10%] mt-5 px-[5%] md:px-[12%] py-10">
                <video loop autoPlay muted playsInline src={videoLiveStrategies}
                       className="shadow-2xl shadow-black">
                </video>
            </div>
            <div
                className="w-[75%] blur-[120px] rounded-full h-32 absolute bg-slate-500/30 z-20"
            />
        </section>
    );
};

export default FeatureSectionStrategy;
