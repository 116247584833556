import React, { useState } from "react";
import HeroTitle from "./HeroTitle";
import CTAButton from "./CTAButton";
import backtestImage from "../images/trade_performance/candlescalper_backtest.jpg";
import equityCurveImage from "../images/trade_performance/candlescalper_equity.jpg";
import monteCarloImage from "../images/trade_performance/candlescalper_montecarlo.jpg";
import "./StrategyDetails.css";
import Counter from "./Counter";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Image from "react-image-enlarger";
import algoTrial from "../images/algo_trial_banner.png";
import imgVS from "../images/trade_performance/VS_NQ_30Min_Equity.png";
import imgVSBacktest from "../images/trade_performance/VS_NQ_30Min_Backtest.png";
import imgGapGuru from "../images/trade_performance/ZTAGapGuru_Equity.png";
import imgGapGuruBacktest from "../images/trade_performance/ZTAGapGuru_Backtest.png";

const StrategyDetails = () => {
  const [isBacktestZoomed, setBacktestZoomed] = useState(false);
  const [isEquityCurveZoomed, setEquityCurveZoomed] = useState(false);
  const [isMonteCarloZoomed, setMonteCarloZoomed] = useState(false);
  const title = "ZTA GapGuru";
  const subtitle =
    "ZTAGapGuru is an intraday system that takes advantage of overnight gaps in ES futures. It is both a trend following (gap and go), and mean reversion (gap fill) system.";
  return (
    <div>
      <div className="">
        <div className="hero-content text-left mb-[120px]">
          <h1
            className="flex-grow pb-[14px] ] mt-[20px] md:mt-4 mb-2 text-center font-bold tracking-tighter
                      bg-gradient-to-tr from-orange-500 to-pink-500
                      text-transparent bg-clip-text pr-2
                      md:mb-6 text-6xl md:text-8xl"
          >
            {title}
            <a />
          </h1>
          <a
            className="font-medium tracking-widest w-[360px] md:w-[800px]
                      text-white
                      text-sm lg:px-0 md:text-md lg:text-lg
                      inline-block"
          >
            <span>{subtitle}</span>
          </a>
          <div
            className="w-full blur-[200px] rounded-full h-72 absolute mt-[200px]
            bg-gradient-to-t from-black to-[deepskyblue]/20 -z-20"
          ></div>
        </div>
        <div className="strategy-image-wrapper">
          <a
            href="https://buy.stripe.com/28odUAeQN3QWdu8dRs"
            target="_blank"
            rel="noopener noreferrer"
          ></a>
        </div>
      </div>

      <div className="strategy-details-container">
        <div className="strategy-details-content">
          <div className="strategy-info">
            <a
              href="https://buy.stripe.com/14k03K2410EK61G9AW"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button
                className="ui-btn-strat mx-start mb-[20px]"
                style={{ backgroundColor: "green" }}
                type="submit"
              >
                <span className="w-full">Get the ZTA Quant portfolio</span>
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="strategy-section">
        <div className="strategy-block">
          <h1>How It Works</h1>
          <p>
            VS NQ 10Min is an intraday Nasdaq futures algorithm that uses
            momentum indicators to define and join trends.
          </p>
        </div>
        <div className="strategy-block">
          <h1>Instruments</h1>
          <p>ES, MES</p>
        </div>
        <div className="strategy-block">
          <h1>Account Size</h1>
          <p>
            ZTAGapGuru is traded as part of the ZTA Quant portfolio. We
            recommend a minimum account balance of at least $100,000 to trade
            the ZTA Quant portfolio with 1 E-mini contract per algo.
          </p>
        </div>
      </div>

      <div>
        <h1 className="historical-title">Historical Performance</h1>
      </div>

      <div className="historical-container">
        <div className="historical-block">
          <h1>Backtest 2008-2024</h1>
          <Image
            src={imgGapGuruBacktest}
            zoomed={isBacktestZoomed}
            alt="Historical backtest results of a futures trading algo named BlueFlame in NinjaTrader"
            onClick={() => setBacktestZoomed(true)}
            onRequestClose={() => setBacktestZoomed(false)}
          />
        </div>
        <div className="historical-block">
          <h1>Equity Curve</h1>
          <Image
            src={imgGapGuru}
            zoomed={isEquityCurveZoomed}
            alt="Historical equity curve performance of the BlueFlame trading algo"
            onClick={() => setEquityCurveZoomed(true)}
            onRequestClose={() => setEquityCurveZoomed(false)}
          />
        </div>
      </div>
      <div>
        <h1 className="historical-title">Performance Highlights</h1>
      </div>
      <div className="highlights-section">
        <div className="highlights-block">
          <Counter endValue={71862} showPrefix={true} />
          <p>Historical Net Profit</p>
        </div>
        <div className="highlights-block">
          <Counter endValue={13773} showPrefix={true} />
          <p>Maximum Drawdown</p>
        </div>
        <div className="highlights-block">
          <h1>60%</h1>
          <p>Win Rate</p>
        </div>
        <div className="highlights-block">
          <Counter endValue={5} showPrefix={false} />
          <p>Net Profit/DD Ratio</p>
        </div>
      </div>

      <div className="disclaimer-section">
        <h1>Disclaimer</h1>
        <p>
          The results shown for this strategy are hypothetical and based on
          simulated returns. They do not represent actual trading results.
          Trading involves risk, and you should only trade with capital you can
          afford to lose. The performance of this strategy in the past does not
          guarantee future results.
        </p>
      </div>
    </div>
  );
};

export default StrategyDetails;
