import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const MonthlyReturnsTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("/th_monthly_returns_data.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => setData(data))
      .catch((error) =>
        console.error("There was a problem fetching the data:", error)
      );
  }, []);

  // Helper function to round numbers to the nearest decimal place
  const roundToNearestDecimal = (num) => {
    return Math.round(num * 10) / 10;
  };

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Date</Th>
          <Th>January</Th>
          <Th>February</Th>
          <Th>March</Th>
          <Th>April</Th>
          <Th>May</Th>
          <Th>June</Th>
          <Th>July</Th>
          <Th>August</Th>
          <Th>September</Th>
          <Th>October</Th>
          <Th>November</Th>
          <Th>December</Th>
          <Th>Year return %</Th>
          <Th>Max DD</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((row, index) => {
          const currentDate = new Date();
          const currentMonth = currentDate.getMonth(); // 0-11 (January is 0, December is 11)
          const currentYear = currentDate.getFullYear();

          const renderMonthData = (monthData, monthIndex) => {
            // If the current year matches the row's year and the month hasn't finished yet, return "-"
            if (row.Year === currentYear && monthIndex > currentMonth) {
              return "-";
            }
            // Otherwise, return the rounded data with a "%" or just "-" if there's no data
            return monthData ? `${roundToNearestDecimal(monthData)}%` : "-";
          };

          return (
            <Tr key={index}>
              <Td>{row.Year}</Td>
              <Td>{renderMonthData(row.January, 0)}</Td>
              <Td>{renderMonthData(row.February, 1)}</Td>
              <Td>{renderMonthData(row.March, 2)}</Td>
              <Td>{renderMonthData(row.April, 3)}</Td>
              <Td>{renderMonthData(row.May, 4)}</Td>
              <Td>{renderMonthData(row.June, 5)}</Td>
              <Td>{renderMonthData(row.July, 6)}</Td>
              <Td>{renderMonthData(row.August, 7)}</Td>
              <Td>{renderMonthData(row.September, 8)}</Td>
              <Td>{renderMonthData(row.October, 9)}</Td>
              <Td>{renderMonthData(row.November, 10)}</Td>
              <Td>{renderMonthData(row.December, 11)}</Td>
              <Td>
                {row["Year return %"]
                  ? `${roundToNearestDecimal(row["Year return %"])}%`
                  : "-"}
              </Td>
              <Td>
                {row["Max DD"]
                  ? `${roundToNearestDecimal(row["Max DD"])}%`
                  : "-"}
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default MonthlyReturnsTable;
