import React from "react";
import HeroTitle from "./HeroTitle";
import blogImage from "../images/trade_performance/tradeorfade_equity.jpg";
import post1Image from "../images/trade_performance/breakoutlong_montecarlo.jpg";
import post2Image from "../images/portfolio_equity_curve.png";
import post3Image from "../images/trade_performance/tradeorfade_equity.jpg";
import post4Image from "../images/man_vs_machine.png";

import "./BlogList.css";
import CTAButton from "./CTAButton";
import { Link } from "react-router-dom";

const dummyBlogPosts = [
  {
    id: 1,
    title: "What Is Automated Trading & How Does It Work?",
    image: post1Image,
    date: "October 22, 2023",
    byline: "Jade Byfield",
    content: `In this blog post, we'll
          demystify automated trading, explain how it works, and explore its
          potential benefits and drawbacks.`,
    readTime: "2 minute",
  },
  {
    id: 2,
    title: "Does Diversification Matter In Algo Trading?",
    image: post2Image,
    date: "October 20, 2023",
    byline: "Jade Byfield",
    content: `Diversification is a fundamental concept in algorithmic trading, and
      it plays a critical role in managing risk and optimizing portfolio
      performance.`,
    readTime: "3 minute",
  },
  {
    id: 3,
    title: "Strategy Backtesting: The Good, Bad, and the Ugly",
    image: post3Image,
    date: "October 15, 2023",
    byline: "Jade Byfield",
    content: `Strategy backtesting is a crucial step in the development of
      algorithmic trading systems. It offers traders and investors valuable
      insights into the performance of their strategies.`,
    readTime: "3 minute",
  },
  {
    id: 4,
    title: "Psychology vs. Algorithms: Emotions in Trading",
    image: post4Image,
    date: "October 10, 2023",
    byline: "Jade Byfield",
    content: `The world of trading is a battleground where emotions often clash with
      rational decision-making.`,
    readTime: "3 minute",
  },
];

const BlogList = () => {
  const subtitle = "Insights, research and news about automated trading.";
  return (
      <div>
        <div className="hero-content text-left mb-[120px]">
          <h1 className="flex-grow pb-[14px] ] mt-[20px] md:mt-4 mb-2 text-center font-bold tracking-tighter
                      bg-gradient-to-b from-cyan-300 to-rose-200
                      text-transparent bg-clip-text pr-2
                      md:mb-6 text-8xl">
            Blog<a/>
          </h1>
          <a className="font-medium tracking-widest w-[360px] md:w-[800px]
                      text-rose-200
                      text-sm lg:px-0 md:text-md lg:text-lg
                      inline-block">
            <span>{subtitle}</span>
          </a>
          <div
              className="w-full blur-[160px] rounded-full h-72 absolute mt-[200px]
            bg-gradient-to-b from-indigo-400/20 to-black -z-20"
          ></div>
        </div>
        <div className="blog-list-container">
          {dummyBlogPosts.map((post) => (
              <div key={post.id} className="blog-card">
                <div className="mx-auto">
                  <img src={post.image} alt={post.title} className="blog-image"/>
                </div>
                <div className="blog-details">
                  <h3 className="font-semibold text-xl tracking-tight pb-4 h-[60px]">{post.title}</h3>
                  <p className="font-normal text-[.9rem] tracking-tight">{post.date}</p>
                  <p className="font-semibold text-[.9rem] tracking-wide">By {post.byline}</p>
                  <br/>
                  <p className="blog-subtitle pb-4">
                    {post.content.slice(0, 160)}...{" "}
                    {/* Display the first 100 characters of content */}
                  </p>
                  <p className="pl-1 pb-1 text-sm font-light tracking-widest mt-[20px]">{post.readTime} read</p>
                  <button className='ui-btn mx-start h-10 w-[175px]'>
                    <Link to={`/blog/post/${post.id}/${post.title
                          .replace(/\s+/g, "-")
                          .toLowerCase()}`}
                    >
                      <span className="font-medium text-[1rem] w-full">Read More</span>
                    </Link>
                  </button>
                </div>
              </div>
          ))}
        </div>
      </div>
  );
};

export default BlogList;
