import React from "react";
import "./StrategyItem.css";
import CTAButton from "./CTAButton";
import "./CTAButton.css";
import { Link } from "react-router-dom";

const StrategyItem = ({ imageSrc, title, description }) => {
  // Encode the title to format the URL correctly
  const formattedTitle = encodeURIComponent(title);

  return (
    <div className="strategy-info-block">
      <img
        src={imageSrc}
        alt="Image"
        className="strategy-info-block__img"
        loading="lazy"
      />
      <div className="strategy-info-block__content">
        <h2 className="strategy-info-block__title">{title}</h2>
        <p className="strategy-info-block__description">
          {description}
          <button className="ui-btn mx-start mt-[56px]">
            <Link to={`/strategies/${formattedTitle}`}>
              <span className="font-semibold w-full">View Performance</span>
            </Link>
          </button>
        </p>
        {/*<Link to={`/strategies/${formattedTitle}`} style={{textDecoration: 'none'}}>*/}
        {/*    <CTAButton title="Get This Algo"/>*/}
        {/*</Link>*/}
      </div>
    </div>
  );
};

export default StrategyItem;
