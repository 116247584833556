import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  ctaButton: {
    background: 'hsl(90, 90%, 40%)',
    color: '#fff',
    borderRadius: '999px',
    padding: '12px 24px',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '18px',
    display: 'block',
    margin: '0 auto',
    marginTop: '20px',
    '&:hover': {
      background: 'hsl(15, 90%, 55%)'
  },
},
});

const CTAButton = ({ title }) => {
  const classes = useStyles();

  return (
    <Button variant="contained" className={classes.ctaButton}>
      {title}
    </Button>
  );
};

export default CTAButton;