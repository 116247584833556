import React from "react";
import Disclaimer from "../components/Disclaimer";

const RiskDisclosure = () => {
  const riskDisclosureContent = (
    <>
      <p>
        Trading and electronic investment are high-risk financial activities
        that can eventually produce important losses, maybe making this activity
        not appropriate for the average investor or trader.
      </p>
      <p>
        Stocks, Futures, Options, and Forex trading contains substantial risk
        and is not for every investor. An investor could potentially lose all or
        more than the initial investment. Risk capital is money that can be lost
        without jeopardizing one’s financial security or lifestyle. Only risk
        capital should be used for trading and only those with sufficient risk
        capital should consider trading. Past performance is not necessarily
        indicative of future results.
      </p>
      <p>
        Hypothetical performance results have many inherent limitations, some of
        which are described below. No representation is being made that any
        account will or is likely to achieve profits or losses similar to those
        shown; in fact, there are frequently sharp differences between
        hypothetical performance results and the actual results subsequently
        achieved by any particular trading program. One of the limitations of
        hypothetical performance results is that they are generally prepared
        with the benefit of hindsight. In addition, hypothetical trading does
        not involve financial risk, and no hypothetical trading record can
        completely account for the impact of financial risk on actual trading.
        For example, the ability to withstand losses or to adhere to a
        particular trading program in spite of trading losses are material
        points that can also adversely affect actual trading results. There are
        numerous other factors related to the markets in general or to the
        implementation of any specific trading program which cannot be fully
        accounted for in the preparation of hypothetical performance results and
        all of which can adversely affect trading results.
      </p>
      <p>
        Any investment or trading decision is at your own financial risk and
        responsibility, not being able to claim Zion Trading Algos, any
        associate or partner for any loss of money.
      </p>

      {/* Added Testimonial Disclaimer */}
      <h3>Customer Testimonials Disclosure:</h3>
      <p>
        Important Notice: The testimonials presented on our website from our
        customers are their personal opinions and experiences with our
        algorithmic trading bots. They are individual results and vary from
        person to person. These testimonials are not intended to make promises
        of outcomes but to share experiences.
      </p>
      <p>
        No Guarantee of Results or Performance: The testimonials on this site
        are not to be interpreted as a promise or guarantee of similar success
        or returns. The trading performance you experience will depend on a
        variety of factors, including but not limited to market conditions, your
        risk tolerance, and trading discipline.
      </p>
      <p>
        Not Financial Advice: The testimonials are provided for informational
        purposes only and should not be construed as investment advice. We do
        not claim that the use of our algorithmic trading bots will result in
        profitable trading or be free of risk of loss.
      </p>
      <p>
        We encourage all potential customers to conduct their own due diligence
        and consult with a qualified financial professional before making any
        investment decisions.
      </p>
    </>
  );

  return (
    <div>
      <Disclaimer title="Risk Disclosure" content={riskDisclosureContent} />
    </div>
  );
};

export default RiskDisclosure;
