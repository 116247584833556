import React from "react";
import PropTypes from "prop-types";
import "./YoutubeEmbed.css";

const YoutubeEmbed = ({ youtubeUrl, width, height }) => (
  <div className="video-wrapper" style={{ maxWidth: width }}>
    <div className="video-container" style={{ height }}>
      <iframe
        src={youtubeUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  </div>
);

YoutubeEmbed.propTypes = {
  youtubeUrl: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

YoutubeEmbed.defaultProps = {
  width: "560px",
  height: "315px",
};

export default YoutubeEmbed;
