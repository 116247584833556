import React, { useState, useEffect, useRef } from 'react';

const Counter = ({ endValue, showPrefix, showPostfix=false, className='font-bold'}) => {
  const [currentValue, setCurrentValue] = useState(0);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          animateCounter();
        }
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );
  
    observer.observe(counterRef.current);
  
    return () => {
      if (counterRef.current instanceof Element) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);
  

  const animateCounter = () => {
    const animationDuration = 2000; // Duration in milliseconds
    const animationSteps = 60; // Number of animation steps

    const increment = endValue / animationSteps;
    let count = 0;

    const timer = setInterval(() => {
      count += increment;
      setCurrentValue(Math.round(count));

      if (count >= endValue) {
        setCurrentValue(endValue);
        clearInterval(timer);
      }
    }, animationDuration / animationSteps);

    return () => {
      clearInterval(timer);
    };
  };

  return (
    <div ref={counterRef}>
      <h1 className={className}>{showPrefix && '$'}{currentValue}{showPostfix && '%'}</h1>
    </div>
  );
};

export default Counter;
