import React from 'react';
import HeroTitle from './HeroTitle';
import './Disclaimer.css'

const Disclaimer = ({ title, content }) => {
  return (
      <div>
    <HeroTitle title={title} />

    <div className="disclaimer">
      <div className="disclaimer-content">
        <p>{content}</p>
      </div>
    </div>
    </div>
  );
};

export default Disclaimer;
