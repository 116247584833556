import React from 'react';

const Checkout = () => {
    return (
        <div>
            <h1>Welcome to Zion Trading Algos</h1>
            <p>Discover amazing trading strategies</p>
        </div>
    );
};

export default Checkout;
