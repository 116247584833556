import React from 'react';

function QuantDisclaimer() {
  return (
    <section className="flex flex-col mt-24 max-w-full w-[1273px] max-md:mt-10">
      <h2 className="self-start text-6xl text-[#C4D1E6] tracking-tighter leading-none text-center max-md:text-4xl">
        Disclaimer
      </h2>
      <p className="mt-8 text-xl text-[#D8D8D8] md:text-2xl tracking-tighter leading-8 max-md:max-w-full">
        The results shown for this strategy are hypothetical and based on simulated returns. They do not represent actual trading results. Trading involves risk, and you should only trade with capital you can afford to lose. The performance of this strategy in the past does not guarantee future results.
      </p>
    </section>
  );
}

export default QuantDisclaimer;