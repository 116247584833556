import React, { useState } from "react";
import { Card, CardContent, Typography, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PriceCardButton from "./PriceCardButton";
import { color, minHeight } from "@mui/system";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  pricingContainer: {
    display: "flex",
    gap: 16,
    margin: "auto",
    justifyContent: "left",
    maxWidth: "1200px",
    maxHeight: "20%",
    marginBottom: "10%",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  pricingCard: {
    flex: 1,
    flexDirection: "column",
    fontFamily: "Montserrat",
    position: "relative",
    fontWeight: "800",
    margin: "14px",
    opacity: 0.8,
    boxShadow: "10px 5px 20px black",
    backgroundColor: "#141721",
    color: "black",
    maxWidth: "50vw",
    maxHeight: "700px",
    borderRadius: "10px",
    "@media (max-width: 768px)": {
      maxWidth: "80vw",
      maxHeight: "90vh",
    },
  },
  inactiveCard: {
    opacity: 0.5,
  },
  pricingCTAContainer: {
    position: "absolute",
    justifyContent: "left",
    bottom: "10px",
    left: 0,
    right: 0,
    marginBottom: "20px",
  },
  pricingCTA: {},
  priceText: {
    fontWeight: "900",
    color: "#44d8a4",
    fontFamily: "Montserrat",
    marginTop: "14px",
    marginBottom: "14px",
  },
  link: {
    textDecoration: "none",
    width: "100%",
  },
  features: {
    marginBottom: "100px",
    fontSize: "1.0rem",
    color: "lightgray",
    textAlign: "left",
    fontWeight: 400,
    lineHeight: 2.4,
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
    th: {
      "@media (max-width: 768px)": {
        color: "#44d8a4",
      },
    },

    li: {
      marginBottom: "10px",
    },
  },
  pricingCTA2: {
    color: "white",
  },
});

const PricingCards = () => {
  const classes = useStyles();
  const [monthlySelected, setMonthlySelected] = useState(true);

  const handleSwitchChange = () => {
    setMonthlySelected(!monthlySelected);
  };

  return (
    <div>
      <div className={classes.pricingContainer}>
        {/* <Card className={classes.pricingCard}>
          <CardContent>
            <Typography variant="h5" component="h2">
              <a
                className="text-3xl font-bold text-[#CCFFFF]
               bg-clip-text"
              >
                1 Trading Algo
              </a>
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              className={classes.priceText}
            >
              $97/mo
            </Typography>
            <ul className={classes.features}>
              <li>
                <th className="inline check pr-3 text-[#44d8a4]">✔</th>
                No setup fee
              </li>
              <li>
                <th className="inline check pr-3 text-[#44d8a4]">✔</th>1 fully
                automated algo
              </li>
              <li>
                <th className="inline check pr-3 text-[#44d8a4]">✔</th>
                Monthly License
              </li>
              <li>
                <th className="inline check pr-3 text-[#44d8a4]">✔</th>
                Discord community support
              </li>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                24/7 Email support
              </li>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                Chat Support
              </li>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                System upgrades &amp; improvements
              </li>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                Optimization guide
              </li>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                Cancel or switch algos at any time
              </li>
            </ul>
          </CardContent>
          <div className={classes.pricingCTAContainer}>
            <Link to="/strategies" className="justify-center flex p-1">
              <div className="buttons">
                <button className="btn">
                  <span></span>
                  <p data-text="Let's Go!" data-title="Get Started!"></p>
                </button>
              </div>
            </Link>{" "}
          </div>
        </Card> */}

        <Card className={`${classes.pricingCard}`}>
          <CardContent>
            <Typography variant="h5" component="h2">
              <a
                className="text-3xl font-extrabold text-[#CCFFFF]
               bg-clip-text"
              >
                ZTA Quant Portfolio
              </a>
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              className={classes.priceText}
            >
              $997/year
            </Typography>
            <ul className={classes.features}>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                No setup fee
              </li>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>12 Algos
                trading ES, NQ, GC, CL, NG
              </li>
              <li className="whitespace-normal">
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                Portfolio Analyzer lifetime license
              </li>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                Strategy templates &amp; settings
              </li>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                Discord community support
              </li>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                24/7 Email support
              </li>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                Chat Support
              </li>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                System upgrades &amp; improvements
              </li>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                Optimization guide
              </li>
              <li>
                <th className=" inline check pr-3 text-[#44d8a4]">✔</th>
                Unlimited access to future algos
              </li>
            </ul>

            <div className={classes.pricingCTAContainer}>
              <Link
                to="/portfolios/zta_quant_1"
                className="justify-center flex p-1"
              >
                <div className="buttons">
                  <button className="btn">
                    <span></span>
                    <p data-text="Let's Go!" data-title="Get Started!"></p>
                  </button>
                </div>
              </Link>
            </div>
          </CardContent>
        </Card>

        {/* <Card className={`${classes.pricingCard}`}>
          <CardContent>
            <Typography variant="h5" component="h2">
              1 System, Lifetime License As Low As
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              className={classes.priceText}
            >
              $497
            </Typography>
            <ul className={classes.features}>
              <li>No setup fee</li>
              <li>1 fully automated algo</li>
              <li>Lifetime License</li>
              <li>Discord community support</li>
              <li>24/7 Email support</li>
              <li>Chat Support</li>
              <li>System upgrades and improvements</li>
              <li>Optimization guide</li>
            </ul>

            <div className={classes.pricingCTAContainer}>
              <Link to="/strategies" className={classes.link}>
                <PriceCardButton
                  className={classes.pricingCTA}
                  title="Get Started"
                  backgroundColor="hsl(90, 90%, 40%)"
                />
              </Link>
            </div>
          </CardContent>
        </Card> */}
      </div>
    </div>
  );
};

export default PricingCards;
