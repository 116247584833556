import React from 'react';
import Disclaimer from '../components/Disclaimer';

const PrivacyPolicy = () => {
    const privacyPolicyContent = (
        <>
          <p>
            This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website or use our services. By accessing or using our website or services, you agree to the terms of this Privacy Policy.
          </p>
          <h4>Information We Collect</h4>
          <p>
            We may collect personal information, such as your name, email address, and other relevant details, when you voluntarily provide it to us. We may also automatically collect certain information through cookies, web beacons, and similar technologies.
          </p>
          <h4>How We Use Your Information</h4>
          <p>
            We use the collected information to provide and improve our services, customize your experience, send you relevant communications, and ensure the security of our website and services. We do not sell or share your personal information with third parties for their marketing purposes.
          </p>
          <h4>Data Security</h4>
          <p>
            We take appropriate measures to protect your personal information from unauthorized access, alteration, or disclosure. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
          </p>
          <h4>Third-Party Links</h4>
          <p>
            Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or the content of those third-party websites.
          </p>
          <h4>Changes to this Privacy Policy</h4>
          <p>
            We reserve the right to modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on our website. We encourage you to review this Privacy Policy periodically for any updates.
          </p>
          <h4>Contact Us</h4>
          <p>
            If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us using the provided contact information.
          </p>
        </>
      );
      
  return (
    <div>
      <Disclaimer title="Privacy Policy" content={privacyPolicyContent} />
    </div>
  );
};

export default PrivacyPolicy;
