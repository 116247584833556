import React from "react";
import HeroTitle from "../components/HeroTitle";
import "./Affiliates.css";
import AffiliatesImage from "../images/zta_affiliates_image.png";
import CTAButton from "../components/CTAButton";
import { Link } from "react-router-dom";

const Affiliates = () => {
  return (
    <div className="main-container">
      <HeroTitle
        title="Empower Traders, Earn Rewards!"
        subtitle="Join our thriving community as an affiliate and start earning generous commissions today!"
      ></HeroTitle>

      <p>
        At Zion Trading Algos, we're revolutionizing the trading world with our
        cutting-edge software, designed to simplify and enhance the experiences
        of traders globally. Now, we're offering you the opportunity to be a
        part of this financial tech revolution - and earn significant rewards in
        the process.
      </p>

      <img
        src={AffiliatesImage}
        alt="Two traders sitting at a desk discussing trading with charts in the background and monitors in front of them"
      ></img>

      <h3>Why Join ZTA's Affiliate Program?</h3>
      <ul>
        <li>
          <strong>Attractive Commissions:</strong> Benefit from a lucrative
          payment structure. Earn 10% on subscription sales and 10% on lifetime
          licenses, with potential for high earning per successful referral.
        </li>
        <li>
          <strong>Exclusive Discounts:</strong> Provide your audience with
          exclusive access to premium trading tools, offering them valuable
          discounts that they won't find anywhere else.
        </li>
        <li>
          <strong>Empower Investors:</strong> Play a role in advancing the
          trading community by promoting software that boosts trading efficiency
          and effectiveness.
        </li>
        <li>
          <strong>Easy Setup, Transparent Tracking:</strong> With our
          easy-to-navigate platform and transparent tracking system, managing
          your referrals and earnings is a breeze.
        </li>
      </ul>

      <h3>How It Works:</h3>
      <ol>
        <li>
          <strong>Sign Up:</strong> Fill out a simple application to get
          started. Once approved, you’ll get access to your unique affiliate
          code and marketing materials.
        </li>
        <li>
          <strong>Promote:</strong> Use your platform to introduce your audience
          to Zion Trading Algos. Offer them exclusive discounts on subscriptions
          and lifetime licenses with your unique code.
        </li>
        <li>
          <strong>Earn:</strong> Receive a generous portion of the initial sale
          whenever your referrals subscribe or purchase a lifetime license.
        </li>
      </ol>

      <h3>Affiliate Payment Structure:</h3>
      <p>
        <strong>Subscriptions:</strong> Your referrals receive a <b>10%</b>{" "}
        discount on their first month with your code, and you earn <b>10%</b> of
        the first month's payment.
      </p>
      <p>
        <em>
          Example: On a $100 subscription, your referral pays $90, and you earn
          $9.
        </em>
      </p>
      <p>
        <strong>Lifetime licenses:</strong> Your referrals receive a <b>10%</b>{" "}
        discount on lifetime licenses with your code, and you earn <b>10%</b> of
        the purchase price.
      </p>
      <p>
        <em>
          Example: On a $1000 license, your referral pays $900, and you earn
          $90.
        </em>
      </p>

      <h3>Join the Revolution:</h3>
      <p>
        Become a part of a community that's setting new standards in the trading
        world. Apply to Zion Trading Algos' affiliate program and start your
        journey toward earning substantial rewards while empowering traders
        globally with superior technology!
      </p>

      <Link
        to="/contact"
        state={{ from: "/affiliates" }}
        style={{ textDecoration: "none" }}
      >
        {" "}
        <CTAButton
          title="Become An Affiliate"
          onClick={() => {
            /* Define your call-to-action functionality here */
          }}
        ></CTAButton>
      </Link>
    </div>
  );
};

export default Affiliates;
