import React from 'react';
import HeroTitle from '../components/HeroTitle';

const ThankYou = () => {
    return (
        <div>
           <HeroTitle title="Thank You For Your Purchase!" subtitle="We appreciate your support. An email with installation instructions will be sent shortly. Please allow up to 24 hours for your machine ID to be added to our licensing database. 
           If you have any questions at all, don't hesitate to reach out to use at hello@ziontradingalgos.com"></HeroTitle>
        </div>
    );
};

export default ThankYou;
