import React from "react";
import HeroTitle from "../components/HeroTitle";
import "./Strategies.css";
import SectionTitleStrategies from "../components/SectionTitleStrategies";

const Strategies = () => {
  return (
    <div className="strategies-container">
      <div className="color_container">
        <SectionTitleStrategies
          title="Our Strategies"
          subtitle="Take a closer look at the strategies in the ZTA Quant Portfolio"
        />
      </div>
    </div>
  );
};

export default Strategies;
