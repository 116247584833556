import React, { useState } from "react";
import "./ContactPopup.css";

function ContactPopup({ buttonText, onClose }) {
  const [email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://ztabackend-8c250e04a1fb.herokuapp.com/contactSubscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }), // Make sure to send the correct data expected by the server
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Response from server:", result.message);
      setFormSubmitted(true);
      setTimeout(onClose, 5000); // Optionally close the modal after a delay
    } catch (error) {
      console.error("Error submitting contact form:", error);
      alert("Failed to send the email, please try again!");
    }
  };

  return (
    <div className="popup-backdrop">
      <div className="popup">
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <h2 className="centered-text">
          {formSubmitted ? "Thank You!" : "Want A Free Algo?"}
        </h2>
        <p className="centered-text">
          {formSubmitted
            ? "You should receive an email soon containing a link to download and set up your free NinjaTrader bot."
            : "Join our community of algorithmic traders and receive a free NinjaTrader trading bot via email."}
        </p>
        {!formSubmitted && (
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              className="input-field"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="submit-button">
              {buttonText}
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default ContactPopup;
