import React, { useState, useEffect } from "react";
import "./HeroTitle.css";
import { Link } from "react-router-dom";

const SectionAboutUs = ({ title, subtitle }) => {
  title = "Automation Over Emotions";
  subtitle =
    "Stop struggling with your emotions and let your machine execute flawlessly. ";
  return (
    <div className="hero-content mx-auto pt-[20px] pb-[40px] w-[1200px] items-center">
      <div className="hero-content text-center">
        <h1
          className="md:mt-2 mb-1 pr-2 pb-[20px] font-bold tracking-tighter
                      bg-gradient-to-bl from-blue-300 to-white
                      text-transparent bg-clip-text text-7xl
                      md:text-8xl md:mb-6"
        >
          About Us
        </h1>
        <p
          className="tracking-wider font-light text-center
                        bg-gradient-to-b from-blue-400 via-indigo-300 to-teal-400
                      text-transparent bg-clip-text
                      w-[1200px] mb-1 pr-2 lg:mb-6 pb-[20px]
                      text-md lg:px-0 md:text-xl lg:text-xl"
        >
          <span>
            We're a team of <a className="font-semibold">anti gurus.</a>
            <br />
            No <a className="font-semibold">scams</a>. No{" "}
            <a className="font-semibold">tricks</a>. No{" "}
            <a className="font-semibold">gimmicks</a>.<br />
            <p className="font-medium tracking-widest">
              Just automated trading systems.
            </p>
          </span>
        </p>
        <div
          className="w-full blur-[160px] rounded-full h-72 absolute mt-[200px]
            bg-gradient-to-b from-indigo-600/20 to-violet-500/20 z-20"
        ></div>
        <a
          className="font-normal tracking-widest w-full px-[5vw] mb-[20px]
                      bg-gradient-to-r from-white via-sky-50 to-white leading-loose
                      bg-clip-text text-transparent text-left
                      text-sm lg:px-0 md:text-md lg:text-lg
                      inline-block"
        >
          <div>
            <p>
              We created
              <a className={"text-violet-400 font-semibold"}> ZTA </a>
              because we noticed that the journey to profitable trading is
              absolutely brutal for the retail trader. It's said that
              <a className={"text-[#61E3D4] font-semibold"}>
                {" "}
                95% of retail traders fail.{" "}
              </a>
              We believe that the main reason for this comes down to two things:
              Not having a technical edge, and emotions.
            </p>
            <br />

            <p>
              We knew that there had to be a better way. We're a team of
              experienced Software Engineers and Traders with 10+ years in the
              tech and trading industries. We tirelessy researched strategies,
              indicators, crowd pyschology behaviors and tools to help us find
              an edge in the markets.{" "}
              <a className={"text-violet-400 font-semibold"}> We succeeded. </a>
            </p>
            <br />

            <p>
              <a className={"text-white font-semibold"}>
                {" "}
                Zion Trading Algorithms{" "}
              </a>{" "}
              is our offering to the average retail trader. These tools were not
              available to us a few years ago, and it's time to leverage
              technology, code and computing power to our advantage. You started
              trading because you wanted financial freedom, and to free up your
              time, so why do you spend 6 hours a day staring at charts?
            </p>
            <br />

            <p>
              Let our systems do the heavy lifting, so that you can get back to
              what matters to you the most.
            </p>
          </div>
        </a>
        <div
          className="w-full blur-[160px] rounded-full h-62 absolute top-16
            bg-gradient-to-t from-[#2C1036] to-black z-20"
        ></div>
      </div>
      <button className="ui-btn mx-auto inline-block mt-[20px] mb-[20px]">
        <Link to="/strategies">
          <span>View Our Algos</span>
        </Link>
      </button>
    </div>
  );
};

export default SectionAboutUs;
