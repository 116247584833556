import HoverCard from "./HoverCard";
import React from "react";
import gridImage1 from "../images/zta_emotional_trader.png";
import gridImage2 from "../images/nt_equity_image_emotions_.png";
import gridImage3 from "../images/zta_gridimage_3.png";
import Slide from "react-reveal/Slide";
import SectionTitleEmotions from "./SectionTitleEmotions";
import { Grid } from "@material-ui/core";
import CustomCard from "./CustomCard";
const EmotionHoverCardSection = () => {
  const cardsData = [
    {
      title: "Eliminate Emotional Bias",
      description:
        "Let our algorithms execute trades based on data and rules, free from human emotions and biases.",
      img: gridImage1,
    },
    {
      title: "Maximize Trading Profits",
      description:
        "Implement robust risk management protocols with automated trading to minimize potential losses and protect capital.",
      img: gridImage2,
    },
    {
      title: "Save Time & Effort",
      description:
        "Free up your time and mental energy by automating your trading, allowing you to focus on what really matters.",
      img: gridImage3,
    },
  ];

  return (
    <div>
      <SectionTitleEmotions />
      <div className="mt-[56px]">
        <div className="mx-auto max-w-[1400px]">
          <Grid container spacing={3} justifyContent={"center"}>
            {cardsData.map((card, index) => (
              <Grid item md>
                <Slide bottom>
                  <HoverCard
                    key={index}
                    title={card.title}
                    description={card.description}
                    imageSrc={card.img}
                  />
                </Slide>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default EmotionHoverCardSection;
